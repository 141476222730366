/* Simple "-s" plurals */
export const withPlural = (value, singular) => {
  return `${value || 0} ${singular}${value === 1 ? "" : "s"}`;
};


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
