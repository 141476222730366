export const EACH_SESSION_LASTS_X_MINUTES = 3;
export const RECOMMENDED_PRICE_IN_US_DOLLARS = 20;
export const PRELOAD_ARTICLES_ON_HOVER = false;


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
