import React from "react";
import Audio from "documents/render/audio";

export default (props) => <Audio src={props.src} inline={"inline" in props} />;


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
