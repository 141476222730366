import Traverse from "documents/render/Traverse";

export default ({ json, data }) => {
  return Traverse({ json, data, index: 0 }) || null;
};


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
