import React from "react";

export default (props) => {
  return <div style={{ height: props.space + "px" }} />;
};


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
