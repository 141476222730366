export const user = (state = null, action) => {
  switch (action.type) {
    case "LOAD_USER":
      return action.content;
    default:
      return state;
  }
};


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
