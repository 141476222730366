import React from "react";

export default () => <div id="page-not-found">Page not found</div>;


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
