import ActivityCalendar, { Skeleton } from "./component/ActivityCalendar.tsx";
import { createCalendarTheme } from "./util";

export { Skeleton, createCalendarTheme };
export default ActivityCalendar;


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
