import React from "react";

export default (props) => <div className="button blue">{props.children}</div>;


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
