import React from "react";

export default (props) => {
  return (
    <h1 id={props.id}>
      <span>{props.children}</span>
    </h1>
  );
};


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
