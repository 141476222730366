import React from "react";

export default (props) => (
  <section className={props.className}>
    <div>{props.children}</div>
  </section>
);


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
