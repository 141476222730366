import React from "react";

export default (props) => {
  return (
    <div
      className={`ylhyra_image ${props.position || "center"}`}
      data-no-translate={true}
    >
      <div style={props.style || {}}>{props.children}</div>
    </div>
  );
};


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
