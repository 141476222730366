import React from "react";

export default (props) => {
  return (
    <div className="toc-vocabulary-list">
      {props.data.sentences.join(" • ")}
    </div>
  );
};


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
