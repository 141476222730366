export const matchWords = /(\p{Script=Latin}+)/giu;
export const matchWordsAndLetters = /([\p{Script=Latin}0-9]+)/giu;
export const matchWordsAndLettersOrSpaces = /([\p{Script=Latin}0-9]+| +)/giu;

// [a-záéíóúýðþæö]


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
