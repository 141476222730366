import * as constants from "app/app/constants";

export default (props) => {
  return constants[props.name] || null;
};


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
